.star_edutainment {
  .banner_bkg {
    background: linear-gradient(270deg, #8c8c8c 0%, #444a63 99.12%);
    img {
      border-color: #a3a8b4;
    }
  }
  .img_reel {
    .heading {
      margin-bottom: 32px;
    }
  }
}
