* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
  color: white;
  font-family: "Epilogue", sans-serif;
  line-height: 1.5rem;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  font-size: 16px;
}

body {
  background: var(--theme-bg);
  height: 100%;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

:root {
  --theme-bg: #000000;
  --white: #ffffff;
  --dark-gray-1: #21262b; //#0d0f14; //#242424; //#21262B
  --light-gray-1: #ccd1dd;
  --dark-pink: #cb006d;
  --blue: #19b5fe;
  --orange: yellow;
  --purple: purple;

  // font-size
  --36px-fs: 2.25rem; //36px
  --35px-fs: 2.1875rem; //35px
  --20px-fs: 1.25rem; //20px
  --18px-fs: 1.125rem; //18px
  --16px-fs: 1rem; //16px
  --14px-fs: 0.875rem; //14px
  height: 100%;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--white);
  background: #cb006d4a;
}

::selection {
  color: var(--white);
  background: #cb006d4a;
}

.bold {
  font-weight: 600;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}
