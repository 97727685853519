header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0 21px;
  background-color: var(--dark-gray-1);
  color: #fff;
  .logo {
    a {
      display: flex;
      align-items: center;
    }
    img {
      width: 60px;
    }
  }
}

header .nav-btn {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-size: 1.5rem;
}

.nav_menu {
  background-color: var(--dark-gray-1);
  font-size: 1.1rem;
  font-weight: 300;
  a {
    padding: 1rem 0;
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #ccd1dd;
    text-decoration: none;
  }
  .active {
    color: #cb006d;
  }
}
