.sidebar {
  position: fixed;
  z-index: 1;
  user-select: none;
  width: 29.6875rem;
  height: calc(100% - 3.75rem);
}

.cover {
  padding-top: 3.025rem;
  padding-left: 2.9375rem;
  margin: 1.875em 3.125rem;
  background: var(--dark-gray-1) 0% 0% no-repeat padding-box;
  height: 100%;
  border-radius: 1.25rem;
}

.menu {
  padding: 69.3px 0px;
  font-size: 1.1rem;
  font-weight: 300;
  a {
    padding: 1rem 0;
    cursor: pointer;
    color: #ccd1dd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
  }
  .active {
    color: #cb006d;
  }
  .active:after {
    content: '';
    background: #cb006d 0% 0% no-repeat padding-box;
    width: 1.8125rem;
    height: 0.25rem;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  .social {
    a {
      color: #ccd1dd;
      font-size: 1rem;
      font-weight: 300;
    }
  }
  .copyright {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
    color: #ccd1dd;
    width: 100%;
  }
}
