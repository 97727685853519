.project_container {
  padding-top: 3.9375rem;
  padding-bottom: 2.2rem;
}

.heading {
  font-size: 2rem;
  font-weight: 300;
  line-height: 2rem;
}

.sub_heading {
  margin-top: 0.9rem;
  margin-bottom: 2rem;
}

.sub_heading,
.description,
.desc_items {
  font-size: 1.1rem;
  font-weight: 200;
  color: #ccd1dd;
  max-width: 1146px;
}

.details_grid {
  margin: 3.125em 0;
  display: flex;
  flex-direction: row;
  .grid_item {
    max-width: 25%;
    flex: 33.33%;
    .label {
      font-size: 1rem;
      font-weight: 200;
      color: #ccd1dd;
      margin-bottom: 1rem;
    }
    list-style-type: none;
    li {
      line-height: 2rem;
      font-size: 1rem;
      font-weight: 200;
      color: #fff;
      &:before {
        display: inline-block;
        content: "";
        background: #7e898d 0% 0% no-repeat padding-box;
        width: 1.125rem;
        height: 0.175rem;
        margin-right: 0.78125rem;
        margin-bottom: 3px;
        vertical-align: middle;
      }
    }
  }
}

.preview_image {
  margin: 3rem 0;
}

.user_flow {
  margin: 6rem 0;
}

.banner_bkg {
  border-radius: 0.625em;
  background: linear-gradient(270deg, #7d888c 0%, #919a9e 99.12%);
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  img {
    border: 5px solid #000000;
    border-radius: 0.625em;
  }
}

.description_block {
  margin: 4rem 0;
  .desc_items {
    margin: 1rem 0;
    ul {
      padding: 0;
      list-style-type: none;
      li {
        font-size: 1rem;
        font-weight: 200;
        color: #fff;
        margin: 0.5rem 0;
        &:before {
          display: inline-block;
          content: "";
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          border: 5px solid #7e898d;
          margin-right: 0.78125rem;
          margin-bottom: 2px;
          vertical-align: middle;
        }
      }
    }
  }
}

.img_bkg {
  border-radius: 1rem;
  background: transparent linear-gradient(119deg, #025379 0%, #013852 100%) 0%
    0% no-repeat padding-box;
  padding: 1.2rem 0.5rem;
}

.img_grid {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  .div1 {
    grid-area: 1 / 1 / 4 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div4 {
    grid-area: 3 / 2 / 4 / 3;
  }
  // .menu_design {
  //   img {
  //     display: inline-block;
  //   }
  // }
}

.img_reel {
  .grid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 2rem;
  }
  .img_container {
    .img_caption {
      margin-top: 1.2rem;
      margin-bottom: 3rem;
      font-weight: 200;
      font-size: 1rem;
    }
  }
  .reel_header {
    color: #fff;
    font-size: 1.5rem;
    margin: 3rem 0 1.1rem 0;
  }
  .img_caption {
    color: #ccd1dd;
    font-size: 0.75rem;
    text-align: center;
    margin: 0.5rem 3rem;
  }
}

.mobile-view {
  .details_grid {
    display: block;
    .label {
      margin: 1rem 0;
    }
  }
  .grid_item {
    max-width: 100%;
  }
  .img_reel {
    .grid {
      grid-template-columns: auto;
    }
  }
  .banner_bkg {
    padding: 0.6rem 0.6rem 0.1rem 0.6rem !important;
  }
}

.allWorksBtnContainer {
  text-align: center;
  margin: 2rem 0 5rem;
  Button {
    background-color: transparent;
    height: 3.4375rem;
    width: 9.5625rem;
    font-size: 1em;
    font-weight: 300;
    border: 0.07em solid #ffffff;
    border-radius: 0.3125em;
    cursor: pointer;
    margin-top: 35px;
    color: #ffffff;
    text-transform: none;
  }
}

.padding-top {
  padding-top: 3rem;
}

.contact_padding {
  padding-bottom: 2.2rem;
}
