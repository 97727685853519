.blog_container {
  padding-top: 3.9375rem;
  padding-bottom: 4.7rem;
}

.heading {
  font-size: 2rem;
  font-weight: 300;
  line-height: 2rem;
}

.sub_heading {
  margin-top: 0.9rem;
  margin-bottom: 2rem;
}

.blogs_grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2.614rem;
  justify-content: flex-start;
}

.blog-card {
  padding: 2.441rem 1.797rem;
  max-width: 638px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0.9375em;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .blog-screen {
    height: 130px;
    img {
      max-width: 110px;
    }
    text-align: center;
  }
  .blog-details {
    padding: 10px 0;
    .blog-date {
      font-size: 18px;
      font-weight: 200;
      line-height: 26px;
    }
    .blog-name {
      padding: 1rem 0;
      font-size: 30px;
      line-height: 37px;
    }
  }
  Button {
    background-color: rgb(0 0 0 / 60%);
    height: 3.4375rem;
    width: 9.5625rem;
    font-size: 1em;
    font-weight: 300;
    border-radius: 0.3125em;
    cursor: pointer;
    color: #ffffff;
    text-transform: none;
    &:hover {
      background-color: #363f42;
    }
  }
  Button:disabled {
    background-color: #8984844a;
    color: #fff;
  }
}

.blog_bg_1 {
  background: linear-gradient(270deg, #593368 0%, #8e858f 99.12%);
}

.blog_bg_2 {
  background: linear-gradient(270deg, #8c8c8c 0%, #444a63 99.12%);
}

.blog_bg_3 {
  background: linear-gradient(270deg, #95876c 0%, #bf764d 99.12%);
}

.blog_bg_4 {
  background: linear-gradient(270deg, #31667b 0%, #987e9f 99.12%);
}

.blog_bg_5 {
  background: linear-gradient(270deg, #416867 0%, #0d6e6e 99.12%);
}

.mobile-view {
  .blogs_grid {
    display: block;
  }
  .blog-card {
    max-width: 100%;
    margin-bottom: 2.1875rem;
    align-items: center;
  }
  .blog_container {
    .heading,
    .sub_heading {
      text-align: center;
    }
  }
}
