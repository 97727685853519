.hawkTrade {
  .grid {
    grid-template-columns: auto auto;
    justify-content: flex-start;
    row-gap: 2rem;
  }
  .preview_image {
    .heading {
      margin-bottom: 32px;
    }
  }
}
