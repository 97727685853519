.projects {
  margin-top: 6.375em;
  color: #ccd1dd;
}

.projects_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.project-card {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0.625em;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .project-screen {
    height: 22.75em;
    overflow: hidden;
  }
  .project-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 23px;
  }
}

.mobile-view {
  .grid {
    grid-template-columns: auto !important;
  }
  .projects_grid {
    display: block;
  }
  .project-card {
    margin-bottom: 2.1875rem;
    .bkg-banner {
      background: none;
    }
  }
  .recent_projects {
    margin-top: 1.875em;
    margin-bottom: 1.875em;
  }
  .project-screen {
    height: auto;
    img {
      width: 100%;
    }
  }
  .project-details {
    text-align: center;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;
  }
  .project-tags {
    justify-content: center;
    font-size: 12px;
  }
}

.bg-gray-1 {
  background: linear-gradient(270deg, #7d888c 0%, #919a9e 99.12%);
}

.bg-gray-2 {
  background: linear-gradient(
    270deg,
    #39445f 0%,
    #3f4a62 51.27%,
    #5b6474 99.12%
  );
}

.bg-gray-3 {
  background: linear-gradient(270deg, #525a63 1.48%, #6b727c 98.87%);
}

.bg-gray-4 {
  background: linear-gradient(270deg, #4c4841 0%, #7e796f 99.08%);
}

.bg-gray-5 {
  background: linear-gradient(270deg, #334568 0%, #8f858b 99.12%);
}

.bg-gray-6 {
  background: linear-gradient(270deg, #8c8c8c 0%, #444a63 99.12%);
}

.bg-gray-7 {
  background: linear-gradient(290.8deg, #56524c 0.99%, #304352 100%);
}

.bg-gray-8 {
  background: linear-gradient(270deg, #504c42 0%, #444f40 99.12%);
}

.project-details {
  // height: 5.813em;
  a {
    text-decoration: none;
  }
  Button {
    background-color: rgb(0 0 0 / 60%);
    height: 3.4375rem;
    min-width: 9.5625rem;
    font-size: 1em;
    font-weight: 300;
    border-radius: 0.5em;
    cursor: pointer;
    color: #ffffff;
    text-transform: none;
    &:hover {
      background-color: #363f42;
    }
  }
  Button:disabled {
    background-color: #8984844a;
    color: #fff;
  }
}

.project-details-header {
  margin-bottom: 18px;
}

.project-detail-items {
  position: relative;
  font-size: inherit;
}

.project-detail-items .hr::before {
  position: absolute;
  left: 0;
  width: 20px;
  content: "";
  z-index: 11;
  top: 50%;
  border: 2px solid var(--blue);
  background-color: var(--blue);
  transform: translate(0, -50%);
}

.project-detail-items .title {
  margin-left: 30px;
}

.project-name {
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: 300;
  line-height: 3rem;
}

.project-description {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 200;
  padding-top: 6px;
}

.project-tags {
  padding: 5px 20px 16px 20px;
  display: flex;
  align-items: center;
  gap: 0.625em;
  .tag {
    background: #0000004d;
    padding: 5px 10px;
    font-size: 0.875em;
    border-radius: 5px;
    font-weight: 300;
  }
}

.black-banner-border {
  border-radius: 0.9375em;
  border: solid 4px #000000;
}

.gray-banner-border {
  border-radius: 0.9375em;
  border: solid 4px #a3a8b4;
}
