.contact_me {
  // padding-top: 8.208rem;
  padding-top: 55px;
}
.msg {
  color: #ffffff;
  font-size: 2rem;
  line-height: 3rem;
  img {
    position: relative;
    top: 3px;
    margin-left: 10px;
  }
}
.info {
  color: #ccd1dd;
  font-size: 1.1rem;
  font-weight: 200;
  .say_hi {
    margin: 0 1rem 4.5rem 0;
  }
}
.email {
  line-height: 7rem;
  a {
    font-size: 2rem;
    color: #cb006d;
    text-decoration: none;
    font-weight: 200;
  }
}
.spacer {
  color: #ccd1dd;
  font-size: 1.1rem;
  font-weight: 200;
  padding-bottom: 55px;
}

.social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  button {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    cursor: pointer;
    width: 250px;
    height: 70px;
    background: var(--dark-gray-1) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 200;
    border: none;
    user-select: none;
    span {
      margin-left: 13px;
    }
    .icon {
      font-size: 20px;
    }
  }
}

.mobile-view {
  .social {
    justify-content: center;
  }
  .contact_me {
    padding: 20px 0;
    text-align: center;
  }
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
