.avatar {
  user-select: none;
  background: var(--dark-gray-1) 0% 0% no-repeat padding-box;
  border-radius: 31.25em;
  width: 7.5em;
  height: 7.5em;
  display: inline-block;
  img {
    position: relative;
    top: 56%;
    left: 48%;
    transform: translate(-50%, -50%);
    transition: opacity 500ms ease-in;
  }
}

.intro {
  .details {
    padding: 3rem 0;
    .name {
      font-size: 2rem;
      font-weight: 300;
      line-height: 3rem;
    }
    .title {
      font-size: 1rem;
      font-weight: 200;
      color: #ccd1dd;
      line-height: 2rem;
    }
    .description {
      margin-top: 24px;
      max-width: 33.375em;
      font-size: 1rem;
      font-weight: 200;
      color: #ccd1dd;
      .company_link {
        color: #cb006d;
        font-weight: 400;
      }
    }
  }
}

.mobile-view {
  .about_me,
  .recent_projects {
    text-align: center;
  }
  .intro {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
