.home {
  height: 100%;
  padding-top: 1.875em;
}

.recent_projects {
  margin: 3rem 0;
  color: #ccd1dd;
  font-size: 1.1rem;
  font-weight: 200;
}
