.App {
  height: 100%;
}

.main {
  // height: 100vh;
  img {
    max-width: 100%;
    user-select: none;
  }
}

.mobile-view {
  padding: 0 21px;
  .main {
    .project_container {
      .close_btn {
        top: 120px !important;
        right: 0 !important;
        button {
          margin: 6px !important;
        }
      }
      .scroll_to_top_btn {
        right: 15px !important;
        bottom: 0 !important;
        button {
          margin: 6px !important;
        }
      }
    }
  }
}

.desktop-view {
  .main {
    height: 100%;
    padding: 0 1.875em 2em 1.7em;
    margin-left: 438px;
  }
}

.wrapper {
  height: 100%;
  width: 100%;
}

StickyBox {
  z-index: 1;
}
