.sonar_watch {
  .preview_image {
    img {
      border-radius: 9px !important;
      border: solid 5px #a3a8b4 !important;
    }
  }
  .banner_bkg {
    padding: 50px;
    background: linear-gradient(270deg, #444f40 0%, #504c42 99.12%);
    img {
      border: none;
      width: 100%;
      border-radius: 0;
    }
  }
  .img_reel {
    .heading {
      margin-bottom: 32px;
    }
  }
}
