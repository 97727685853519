.error_page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .info {
    color: #ffffff;
    font-size: 2.125rem;
    font-weight: 300;
  }

  Button {
    background-color: transparent;
    height: 3.4375rem;
    width: 9.5625rem;
    font-size: 1em;
    font-weight: 300;
    border: 0.07em solid #ffffff;
    border-radius: 0.3125em;
    cursor: pointer;
    margin-top: 35px;
    color: #ffffff;
    text-transform: none;
  }
}
